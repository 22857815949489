<template>
  <div class="Newsinvitepar">
        <div class="top-title">
      <div class="top-text">/修改权限</div>
    </div>
    <!-- 表单 -->
    <div class="forms">
      <el-form ref="form" label-width="100px" enctype="multipart/form-data">
        <div class="Jurisdiction-form">
        </div>
        <div class="Jurisdiction-form">
          <el-form-item label="设置新账号：" >
            <el-input v-model="listpar.a"></el-input>
          </el-form-item>
        </div>
        <div class="Jurisdiction-form">
          <el-form-item label="设置新密码：" >
            <el-input v-model="listpar.b"></el-input>
          </el-form-item>
        </div>
        <div class="Jurisdiction-form">
          <el-form-item label="设置新备注：" >
            <el-input v-model="listpar.c"></el-input>
          </el-form-item>
        </div>

      </el-form>
      <div class="form-btn">
       <el-button
          type="primary"  class="biy-icon" icon="el-icon-check"
          @click="SinviteBtn()">提交</el-button>
      </div>
    </div>

  </div>
</template>
<script>
import fakelist from "@/api/fakelist.js"
import apiurl from "@/api/apiurl/apiurl.js";
import config from "@/request/config.js"


export default {
  name: "newsinvite",
  components: { },
  data() {
    return {
      listpar: {},
    };
  },
  created() {
  },
  mounted() {},

  methods: {
        //弹窗
      SinviteBtn(e){
          this.etype = e
          this.$confirm('是否确认操作', '消息提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确认',
          cancelButtonText: '取消操作'
        }).then(() => {
          this.SinviteBtns()
          }).catch(action => {
            console.log("取消");
          })
      },

    //提交接口
      async SinviteBtns(){
      let res = await apiurl.entityDataHandle({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家

        bizCatalog:'AAAEntity',
        handleMode:'upsertServicerRoleUser',
        bizData:{
            id:this.$route.params.id,//id为空则为新增角色账号 非空则更新角色账号信息
            loginName:this.listpar.a,//登录号
            loginPwd:this.listpar.b,//新登录密码 为空则不修改
            remark:this.listpar.c,//备注    
        }
        })
        if(this.$common.verifyAjaxResult(res,false)==false) return;
          if(this.$common.verifyAjaxResult(res,false)==false) return;
         this.$alert('设置成功', '消息提示', {confirmButtonText: '确定'});
        this.$router.push({ path: `/home/Subusers` });
      },


  },
};
</script>

<style  scoped>

/* 搜索栏样式 */
.top-title {
  width: 94%;
  margin: 0 auto;
  height: 80px;
}

.forms {
  width: 800px;
  margin: 0 auto;
}
.Jurisdiction-form {
  width: 400px;
  /* margin: 0 auto; */
}
.form-btn {
  width: 600px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding-top: 50px;
  padding-bottom: 100px;
}
.form-btn .biy-icon {
  width: 250px;
  border-radius: 10px;
  background-color: #2ab6708a;
}
.form-btn .biy-icon:nth-of-type(1) {
  background-color: #01847f;
}

@import "../../assets/css/liststyle.css";
</style>